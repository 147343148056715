<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Bus Feature</h4>
    </vs-row>

    <vs-row vs-w="6">
       <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Category Feature</span>
        <v-select
          label="name"
          :options="featureCategoryOptions"
          :reduce="(category) => category.id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="payload.feature_category_id"
        />
      </vs-col>
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Feature Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";
// Store Module
import busFeatureStore from "@/store/bus-feature";

export default {
  name: "BusFeatureEdit",

  metaInfo: {
    title: "Edit Bus Feature",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    vSelect,
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },
  },

  data: () => ({
    payload: {
      name: "",
      feature_category_id: "",
    },
    featureCategoryOptions: [],
  }),

  methods: {
    getFeatureCategories() {
      axios
        .get("/feature-category")
        .then(({ data: res }) => {
          this.featureCategoryOptions = res.data.map((category) => {
            return {
              id: category.id,
              name: category.name,
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },
    async getData() {
      const response = await this.$store.dispatch(
        "busFeature/fetchBusFeature",
        this.decryptedID
      );
      this.payload.name = response.message.name;
      this.payload.feature_category_id = response.message.feature_category.id;
      this.$vs.loading.close();
    },

    async storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("feature_category_id", this.payload.feature_category_id);
      formData.append("_method", "PUT");
      const response = await this.$store.dispatch("busFeature/updateBusFeature", {
        id: this.decryptedID,
        body: formData,
      });
      if (!response.success) {
        this.$catchErrorResponse(response.message);
        return;
      }

      this.$vs.notify({
        title: "Successful",
        text: response.msg_status,
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
      });

      this.$router.push("/bus-feature");
    },
  },

  created() {
    if (!busFeatureStore.isRegistered) {
      this.$store.registerModule("busFeature", busFeatureStore);
      busFeatureStore.isRegistered = true;
    }

    this.getFeatureCategories();
    this.getData();
  },
};
</script>

<style></style>
